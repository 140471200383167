import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import { ROUTES } from "constants/routes";
import Survey from "pages/Survey";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.SURVEY} element={<Survey />} />
      <Route path="*" element={<Navigate to={ROUTES.SURVEY} replace />} />
    </Routes>
  );
};

export default Router;
