import React, { Fragment } from "react";
import { FIELDS_TYPES } from "constants/fieldsTypes";
import InputField from "./InputField";
import CheckboxField from "./CheckboxField";
import SelectField from "./SelectField";
import DateAndTimePickerField from "./DateAndTimePickerField";
import { SurveyData } from "pages/Survey/types";
import GroupQuestions from "./GroupQuestions/GroupQuestions";

interface FieldsContainerProps {
  fieldsData: SurveyData.Group[];
}

const FieldsContainer: React.FC<FieldsContainerProps> = ({ fieldsData }) => {
  const fieldsComponents = (
    data: SurveyData.SurveyQuestions,
    groupUUID: string
  ) => {
    const { question, answerType, listOfAnswers, required, questionUUID } =
      data;

    const attributes = {
      required,
      name: `${answerType.answerTypeUUID},${questionUUID}, ${groupUUID}`,
      label: question,
    };

    switch (answerType.answerTypeUUID) {
      case FIELDS_TYPES.INPUT_TEXT:
        return <InputField {...attributes} />;
      case FIELDS_TYPES.INPUT_NUMBER:
        return <InputField type={"number"} {...attributes} />;
      case FIELDS_TYPES.TEXTAREA:
        return <InputField type={"textarea"} {...attributes} />;
      case FIELDS_TYPES.CHECKBOX:
        return <CheckboxField {...attributes} />;
      case FIELDS_TYPES.SELECT_NUMBER:
        return <SelectField options={listOfAnswers} {...attributes} />;
      case FIELDS_TYPES.SELECT_NUMBERS:
        return <SelectField options={listOfAnswers} multiple {...attributes} />;
      case FIELDS_TYPES.SELECT_TEXT:
        return <SelectField options={listOfAnswers} {...attributes} />;
      case FIELDS_TYPES.SELECT_TEXTS:
        return <SelectField multiple options={listOfAnswers} {...attributes} />;
      case FIELDS_TYPES.DATE:
        return <DateAndTimePickerField {...attributes} />;
      case FIELDS_TYPES.TIME:
        return <DateAndTimePickerField onlyTime {...attributes} />;
      case FIELDS_TYPES.DATE_TIME:
        return <DateAndTimePickerField showTime {...attributes} />;
    }
  };

  return (
    <>
      {fieldsData?.map((group, groupIndex) => {
        //If !!groupName => group questions in one block with group name
        if (!!group.groupName) {
          return (
            <GroupQuestions group={group}>
              {group.questions.map((question, index) => (
                <Fragment key={index}>
                  {fieldsComponents(question, group.groupUUID)}
                </Fragment>
              ))}
            </GroupQuestions>
          );
        } else {
          return group.questions.map((question, index) => (
            <Fragment key={index}>
              {fieldsComponents(question, group.groupUUID)}
            </Fragment>
          ));
        }
      })}
    </>
  );
};

export default FieldsContainer;
