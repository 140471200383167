import React from "react";
import Form, { FormItemProps } from "antd/es/form";
import Select from "antd/es/select";

interface SelectFieldProps {
  options: number[] | string[];
  multiple?: boolean;
}

const SelectField: React.FC<FormItemProps & SelectFieldProps> = ({
  name,
  label,
  options,
  multiple,
  required,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required, message: "Заповніть це поле" }]}
    >
      <Select allowClear mode={multiple ? "multiple" : undefined}>
        {options.map((item, index) => (
          <Select.Option key={index} value={String(item)}>
            {String(item)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectField;
