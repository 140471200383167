import { useState } from "react";
import { AxiosResponse } from "axios";
import { NotificationType, SurveyData } from "pages/Survey/types";

interface IAsyncJsonData {
  jsonData: SurveyData;
  isLoading: boolean;
  errorData: string;
  handleGetJson: (
    apiCall: Promise<AxiosResponse>,
    notification?: (type: NotificationType, message: string) => void
  ) => void;
}

const useAsyncJsonData = (): IAsyncJsonData => {
  const [jsonData, setJsonData] = useState<any>(null);
  const [errorData, setErrorData] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetJson = async (
    apiCall: Promise<AxiosResponse>,
    notification?: (type: NotificationType, message: string) => void
  ) => {
    setIsLoading(true);
    try {
      const { data } = await apiCall;

      if (data.status === "success") {
        await setJsonData(data.data);
      } else if (data.status === "error") {
        await setErrorData(data.data);
      }
    } catch (error: any) {
      if (typeof notification === "function") {
        notification("error", error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { jsonData, isLoading, handleGetJson, errorData };
};

export default useAsyncJsonData;
