import React from "react";
import Form, { FormItemProps } from "antd/es/form";
import Checkbox from "antd/es/checkbox/Checkbox";
import styles from "../Fields.module.scss";

const CheckboxField: React.FC<FormItemProps> = ({ name, label, required }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      valuePropName="checked"
      rules={[{ required, message: "Заповніть це поле" }]}
      className={styles.checkbox}
    >
      <Checkbox />
    </Form.Item>
  );
};

export default CheckboxField;
