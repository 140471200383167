import { ReactNode } from "react";

import styles from "./GroupQuestions.module.scss";

import { SurveyData } from "pages/Survey/types";

interface GroupQuestionsProps {
  children: ReactNode;
  group: SurveyData.Group;
}

const GroupQuestions = ({ children, group }: GroupQuestionsProps) => {
  return (
    <div className={styles.group_container}>
      <div className={styles.group_name}>
        <div style={{ margin: 0 }}>{group.groupName}</div>
      </div>
      <div style={{ marginTop: 15 }}>{children}</div>
    </div>
  );
};

export default GroupQuestions;
