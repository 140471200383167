import { LogoApi } from "pages/Survey/api";
import { useEffect, useState } from "react";

const Logo = () => {
  const [logoSrc, setLogoSrc] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const data = await LogoApi.getLogo();
        setLogoSrc(data.url);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        marginBottom: 30,
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      {logoSrc && (
        <img
          src={logoSrc}
          style={{
            display: "block",
            height: "100%",
            maxHeight: 70,
            width: "100%",
          }}
        />
      )}
    </div>
  );
};

export default Logo;
