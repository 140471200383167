import { Button, Form } from "antd";
import { SurveyData } from "../types";
import FieldsContainer from "components/Fields";
import { Store } from "antd/es/form/interface";
import { useWindowSize } from "usehooks-ts";

interface SurveyFormProps {
  jsonData: SurveyData;
  handleFormSubmit: (values: any) => void;
  initialValues: () => Store | undefined;
  isLoading: boolean;
}

const SurverForm = ({
  jsonData,
  handleFormSubmit,
  initialValues,
  isLoading,
}: SurveyFormProps) => {
  const { width } = useWindowSize();

  const textWithLineBreaks = (text: string) => {
    return text.split("\n").map((line, index) => (
      <div key={index}>
        {line}
        <br />
      </div>
    ));
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        {textWithLineBreaks(jsonData.surveyTemplate.headerText)}
      </div>
      <Form
        onFinish={handleFormSubmit}
        initialValues={initialValues()}
        noValidate
        labelCol={width > 740 ? { span: 13 } : undefined}
        labelWrap={true}
        labelAlign="left"
        colon={false}
      >
        <FieldsContainer fieldsData={jsonData?.groups} />

        <Button
          type="primary"
          htmlType="submit"
          className="btnSend"
          loading={isLoading}
        >
          Відправити
        </Button>
      </Form>
      <div style={{ marginTop: 20 }}>
        {textWithLineBreaks(jsonData.surveyTemplate.footerText)}
      </div>
    </>
  );
};

export default SurverForm;
