export const FIELDS_TYPES = {
  INPUT_TEXT: "f222c8fa-800d-4412-a061-0e3adb70434b",
  INPUT_NUMBER: "03b81636-15ee-4c0a-a059-52b2462bda8e",
  TEXTAREA: "b63adcab-4838-403f-99a4-e53ce1667f1d",
  CHECKBOX: "41cb2588-fe15-4db9-8d02-75f57601a2b6",
  SELECT_NUMBER: "2977117f-c1af-4008-b3aa-40d861c2cbba",
  SELECT_TEXT: "cd90ccaa-dd4c-41f2-b53f-ac889f085885",
  SELECT_NUMBERS: "6054aa01-5a48-480b-b62c-41167126765c",
  SELECT_TEXTS: "ea767b80-ef22-419c-a268-f09186b54302",
  DATE: "88a52eb0-8cdc-4782-a2ea-1196b1092f38",
  TIME: "98ca6bca-b98f-4f22-8b7b-de6999c9068c",
  DATE_TIME: "3088be34-4f05-405c-9d2a-044cecc13211",
};
