import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Spin from "antd/es/spin";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import "./style.scss";

import useUrlSearchParams from "services/hooks/useUrlSearchParams";
import useAsyncJsonData from "services/hooks/useAsyncJsonData";
import { getSurveyAPI, putSurveyAPI } from "./api";
import { FIELDS_TYPES } from "constants/fieldsTypes";
import notification from "antd/es/notification";
import { NotificationType } from "./types";
import { SHOW_TIME_FORMAT } from "constants/dateFormats";
import Logo from "components/Logo";
import SurverForm from "./components/SurverForm";

dayjs.extend(isSameOrAfter);

const Survey: React.FC = () => {
  const query = useUrlSearchParams();
  const queryId = query.get("id");

  const { jsonData, isLoading, handleGetJson, errorData } = useAsyncJsonData();

  const [isSuccessfully, setIsSuccessfully] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  // const checkDueDate = dayjs().isSameOrAfter(dayjs(jsonData?.dueDate));

  useEffect(() => {
    handleGetJson(getSurveyAPI(queryId));
  }, []);

  const handleFormSubmit = async (values: any) => {
    const questions = Object.keys(values).map((item) => {
      //Form name includes answerTypeUUID and questionUUID.
      //We have to separate this two values

      const itemInfo = item.split(",");
      const itemInfoObj = {
        answerTypeUUID: itemInfo[0],
        questionUUID: itemInfo[1],
        groupUUID: itemInfo[2],
      };

      let answers: any = [];
      if (
        itemInfoObj.answerTypeUUID === FIELDS_TYPES.DATE ||
        itemInfoObj.answerTypeUUID === FIELDS_TYPES.DATE_TIME
      ) {
        const dateWithoutMilliseconds = dayjs(values[item])
          .toISOString()
          .substring(0, 19);

        answers.push(dateWithoutMilliseconds);
      } else if (itemInfoObj.answerTypeUUID === FIELDS_TYPES.TIME) {
        answers.push(dayjs(values[item]).format(SHOW_TIME_FORMAT));
      } else if (
        itemInfoObj.answerTypeUUID === FIELDS_TYPES.INPUT_NUMBER ||
        itemInfoObj.answerTypeUUID === FIELDS_TYPES.SELECT_NUMBER
      ) {
        answers.push(+values[item]);
      } else if (itemInfoObj.answerTypeUUID === FIELDS_TYPES.SELECT_NUMBERS) {
        if (Array.isArray(values[item])) {
          answers.push(values[item].map((item: string[]) => +item));
        } else {
          answers.push(+values[item]);
        }
      } else {
        answers.push(values[item]);
      }
      return {
        questionUUID: itemInfoObj.questionUUID,
        groupUUID: itemInfoObj.groupUUID,
        answers: answers.flat(Infinity),
      };
    });

    await handleGetJson(putSurveyAPI(queryId, { questions }), openNotification);

    setIsSuccessfully(true);
  };

  const initialValues = () => {
    let obj: any = {};

    jsonData?.groups.map((group) =>
      group.questions.map(
        ({ answerType, defaultAnswer, questionUUID, answers }) => {
          const initialValue = jsonData?.filled ? answers : defaultAnswer;
          const { answerTypeUUID } = answerType;

          if (
            answerTypeUUID === FIELDS_TYPES.DATE ||
            answerTypeUUID === FIELDS_TYPES.DATE_TIME
          ) {
            obj[answerTypeUUID] = dayjs(String(initialValue));
          } else if (answerTypeUUID === FIELDS_TYPES.TIME) {
            obj[answerTypeUUID] = dayjs(String(initialValue), SHOW_TIME_FORMAT);
          } else if (answerTypeUUID === FIELDS_TYPES.SELECT_NUMBER) {
            obj[answerTypeUUID] = String(initialValue);
          } else if (
            answerTypeUUID === FIELDS_TYPES.SELECT_NUMBERS ||
            answerTypeUUID === FIELDS_TYPES.SELECT_TEXTS
          ) {
            obj[answerTypeUUID] = [String(initialValue)];
          } else {
            obj[answerTypeUUID] = initialValue;
          }
          obj[answerTypeUUID + "questionUUID"] = questionUUID;
        }
      )
    );

    return obj;
  };

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({ message });
  };

  return (
    <div className="container">
      {contextHolder}
      {isLoading ? (
        <div className="spin">
          <Spin size={"large"} />
        </div>
      ) : jsonData || errorData ? (
        <div className="formContainer">
          <Logo />
          {isSuccessfully ? (
            <div>{jsonData.messageSuccess}</div>
          ) : (
            <div>
              {jsonData &&
              jsonData.groups.some((data) =>
                data.hasOwnProperty("questions")
              ) ? (
                <SurverForm
                  handleFormSubmit={handleFormSubmit}
                  jsonData={jsonData}
                  initialValues={initialValues}
                  isLoading={isLoading}
                />
              ) : errorData ? (
                <div>{errorData}</div>
              ) : undefined}
            </div>
          )}
        </div>
      ) : (
        "Помилка. Спробуйте пізніше."
      )}
    </div>
  );
};

export default Survey;
