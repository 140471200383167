import React from "react";
import Input, { InputProps } from "antd/es/input";
import Form, { FormItemProps } from "antd/es/form";

const InputField: React.FC<InputProps & FormItemProps> = ({
  name,
  label,
  placeholder,
  required,
  type = "text",
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required, message: "Заповніть це поле" }]}
    >
      {type === "textarea" ? (
        <Input.TextArea placeholder={placeholder} autoSize={{ minRows: 2 }} />
      ) : (
        <Input type={type} placeholder={placeholder} />
      )}
    </Form.Item>
  );
};

export default InputField;
