import { API_BASE_URL } from "api/baseurl";
import axios, { AxiosResponse } from "axios";

const GET_SURVEYS = `${API_BASE_URL}/surveys/:id`;
const PUT_SURVEYS = GET_SURVEYS;

export const getSurveyAPI = (id: string | null): Promise<AxiosResponse> => {
  return axios.get(GET_SURVEYS.replace(":id", String(id)));
};

export const putSurveyAPI = (
  id: string | null,
  data: any
): Promise<AxiosResponse> => {
  return axios.put(PUT_SURVEYS.replace(":id", String(id)), data);
};

export const LogoApi = {
  getLogo: async () => {
    const { data } = await axios.get(`${API_BASE_URL}/logo`, {
      params: {
        origin,
      },
      responseType: "blob",
    });

    let url = "";
    if (data.type === "image/svg+xml;charset=utf-8") {
      const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
      url = URL.createObjectURL(blob);
    } else if (data.type !== "application/json") {
      const blob = new Blob([data], { type: "text/html" });
      url = URL.createObjectURL(blob);
    }

    return { url };
  },
};
