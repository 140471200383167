import React from "react";
import Form, { FormItemProps } from "antd/es/form";
import DatePicker from "antd/es/date-picker";

import {
  SHOW_DATE_FORMAT,
  SHOW_DATE_TIME_FORMAT,
  SHOW_TIME_FORMAT,
} from "constants/dateFormats";
import TimePicker from "antd/es/time-picker";

interface DateAndTimePickerFieldProps {
  onlyTime?: boolean;
  showTime?: boolean;
}

const DateAndTimePickerField: React.FC<FormItemProps & DateAndTimePickerFieldProps> = ({
  name,
  label,
  required,
  showTime = false,
  onlyTime = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required, message: "Заповніть це поле" }]}
    >
      {onlyTime ? (
        <TimePicker format={SHOW_TIME_FORMAT} />
      ) : (
        <DatePicker
          format={showTime ? SHOW_DATE_TIME_FORMAT : SHOW_DATE_FORMAT}
          showTime={showTime}
        />
      )}
    </Form.Item>
  );
};

export default DateAndTimePickerField;
